import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/cookie-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/cookie-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-cookies/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politicia-de-cookies/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/cookie-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/cookie-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
              <h1>Cookie policy</h1>
                <p>
                Questa Cookie Policy è progettata per informarti sulle nostre modalità di raccolta dei dati personali attraverso i cookie e altre tecnologie di tracciamento (come gif, web beacon, ecc.).
                </p>
                <p>
                Le funzionalità per le quali utilizziamo queste tecnologie possono includere quanto segue:
                </p>
                <ul>
                  <li>Assistenza per la navigazione,</li>
                  <li>
                  Assistenza nella registrazione per i nostri eventi, accesso e capacità di fornire feedback,{" "}
                  </li>
                  <li>
                  Analisi dell'uso dei nostri prodotti, servizi o applicazioni,{" "}
                  </li>
                  <li>
                  Analisi dell’efficacia delle nostre campagne promozionali (compresa la pubblicità comportamentale),{" "}
                  </li>
                  <li>
                  Offrire contenuti di terze parti (come i contenuti dei social media).{" "}
                  </li>
                </ul>
                <p>
                Di seguito è riportato un elenco dettagliato dei cookie che utilizziamo, con una descrizione. Classifichiamo i cookie nelle seguenti categorie:
                </p>
                <ul>
                  <li>Cookie strettamente necessari</li>
                  <li>Cookie di prestazioni</li>
                  <li>Cookie funzionali</li>
                  <li>Cookie di targeting</li>
                </ul>
                <p>
                Tranne nei casi consentiti dalla legge applicabile, inseriamo i cookie solo dopo aver ricevuto il Suo consenso tramite il banner dei cookie o avere modificato le impostazioni nel pannello del Suo browser. È possibile modificare le impostazioni dei cookie per categoria di cookie (ad eccezione dei cookie strettamente necessari per il corretto funzionamento del sito) in qualsiasi momento facendo clic sul pulsante "impostazioni cookie" di seguito:
                </p>
                <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Policy</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
                
                  <h2>Collegamenti ad altri siti Web</h2>
                
                <p>
                  Questo sito può contenere collegamenti o riferimenti ad altri siti web. Si fa presente che non controlliamo i cookie / le tecnologie di monitoraggio di altri siti web e questa Cookie Policy non si applica a tali siti web.
                </p>
                
                  <h2>Come contattarci</h2>
                
                <p>
                In caso di domande, commenti o dubbi sulla presente Cookie Policy o sulle pratiche di informazione di questo sito, utilizzare le informazioni di contatto incluse nella nostra Informativa sulla privacy.
                </p>
                
                  <h2>Modifiche a questa Cookie Policy</h2>
                
                <p>
                Se la presente Cookie Policy cambia, essa sarà pubblicata su questo sito. Questa Cookie Policy è stata aggiornata l'ultima volta 4 aprile 2022.
                </p>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />               
        
      </div>
    );
  }
}

export default Page;
